import React, {Component} from "react"
import {Link, graphql} from "gatsby"

import {FacebookProvider, Share} from 'react-facebook';
import ShareLink from 'react-twitter-share-link';

class Social extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <FacebookProvider appId="393041694503435">
                <ul className="posts__social">
                    <li className="social__li">
                        <Share href={this.props.link}>

                            {({ handleClick, loading }) => (
                                <button className="social__link" onClick={handleClick}>F</button>
                            )}
                        </Share>
                    </li>
                    <li className="social__li">
                        <ShareLink link={this.props.link}>
                            {link => (<a className="social__link" href={link} target='_blank'>T</a>)}
                        </ShareLink>
                    </li>
                </ul>
            </FacebookProvider>

        )
    }
}

export default Social